import React from "react";

const ProductionPage = () => {
    return (
        <div className="head">
            <h1>Welcome to </h1>
            <h1 className="title">NetsCode</h1>
            <div className="head-p">
                <p>NetsCode is currently under production</p>
                <p>Our website will be live around 2025</p>
            </div>
      </div>
    );
}

export default ProductionPage;