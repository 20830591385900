import ProductionPage from "./Components/ProductionPage";

function App() {
  return (
    <div className="App">
      <ProductionPage />
    </div>
  );
}

export default App;
